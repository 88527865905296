<template>
  <nut-navbar v-if="source !== 'toProdetail' && source !== 'toOrder'" @on-click-back="returnClick()" title="企业信息登记"></nut-navbar>
  <div class="content">
    <div class="blank10"></div>
    <!-- <div class="top-info">
      <nut-icon name="left"></nut-icon>
      <h4 class="big-text">企业信息登记</h4>
    </div> -->
    <div class="blank10"></div>
    <div class="normal-formbox newform">
      <form :model="forgetform">
        <nut-row>
          <nut-col :span="24" class="form-item">
            <nut-input 
              placeholder="请规范填写企业名称" 
              v-model="forgetform.name" 
              @clear="clear" 
            />
            <em class="text-red" style="font-size: 10px">*注：</em>
            <em style="font-size: 10px;color:#808080">请规范填写企业名称，例如：山东山大华天软件有限公司</em>
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input placeholder="请输入手机号" v-model="forgetform.phone" @clear="clear" />
          </nut-col>
          <nut-col :span="24" class="form-item lg-btn">
            <nut-button plain type="info" :loading="isloading" class="blue-btn" @click="registerClick()">
              {{ btnContent }}
            </nut-button>
          </nut-col>
        </nut-row>
      </form>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, onMounted, ref, nextTick } from 'vue'
import { Notify } from "@nutui/nutui";
import { useRoute, useRouter } from 'vue-router';
import{ getCookie, setCookie } from '@/utils/cookie'

export default {
  name: 'CorporateInfoSet',
  setup () {
    const router = useRouter();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    // 判断是否来自VIP购买页面
    const source = ref('')
    if (route.query.source !== undefined) {
      source.value = route.query.source
    }
     // 判断是否是来自app 0 安卓 1 苹果
    if (route.query.fromtype) {
      setCookie('fromtype', route.query.fromtype)
    }
    const fromtype = ref()
    if (getCookie('fromtype')) {
      fromtype.value = getCookie('fromtype')
    }
    console.log('fromtype: ' + fromtype.value)
    if (route.query.onlineid) {
      setCookie('onlineid', route.query.onlineid)
    }
    const onlineid = ref()
    if (getCookie('onlineid')) {
      onlineid.value = getCookie('onlineid')
    }
    console.log('onlineid: ' + onlineid.value)
    //运行点击按钮
    const isloading = ref(false);
    const btnContent = ref('登记');
    //Base64 加密解密
    let Base64 = require('js-base64').Base64
    //获取参数
    let innerId = '';
    if (route.query.innerId !== undefined) {
      innerId = Base64.decode(route.query.innerId);
    }
    const forgetform = reactive({
      phone: '',
      name: '',
    })

    // const change = (value, event) => {
    //   if (forgetform.name !== '') {
    //     // nextTick(() => {
    //     //   forgetform.name = value.replace(/[^\u4e00-\u9fa5\a-\z\A-\Z0-9\@\#\$\&\*\(\)\"\'\\\-\+\=\/\+\=\/\;\:\,\.\!\?\ \\n]/g,'')
    //     //     // .replace(/^((?![\u3000-\u303F])[\u2E80-\uFE4F]|\·)*(?![\u3000-\u303F])[\u2E80-\uFE4F](\·)*$/g, '')
    //     // })
    //     // const nameRegular = /^[\u4e00-\u9fa5\a-\z\A-\Z0-9\@\#\$\&\*\(\)\"\'\\\-\+\=\/\+\=\/\;\:\,\.\!\?\ \\n]/g;
    //     // if (!nameRegular.exec(forgetform.name)) {
    //     //   Notify.warn('请输入有效名称!');
    //     //   return false;
    //     // }
    //     // const nameRegular = /^[\u4e00-\u9fa5_a-zA-Z0-9_@_#_$_&_*_(_)_"_"_\\_\-_+_=_/_;_:_,_._!_?_ _\n]+$/;
    //     // if (!nameRegular.exec(forgetform.name)) {
    //     //   Notify.warn('请输入有效名称!');
    //     //   return false;
    //     // }
    //   }
    //   console.log('change: ', value, event)
    // }
    const clear = (value) => {
      console.log('clear:', value);
    }
    //返回登录页面
    const returnClick = () => {
      router.push("/phoneWeb/")
    }
    //登记
    const registerClick = () => {
      //1、校验企业名称和手机号不能为空
      if (forgetform.name == null || forgetform.name === '') {
        Notify.warn('企业名称不能为空！');
        return false;
      }
      const nameRegular = /^[\u4e00-\u9fa5_\u0027\u0022\u002d_a-zA-Z0-9_@_#_$_&_*_(_)_\\_-_+_=_/_;_:_,_._!_?_ _\n]+$/;
      // const nameRegular = /[A-Za-z\\u4E00-\\u9FA50-9@#$&*()'\"\-+= /;:,.!?]+$/;
      // const nameRegular = ".*[^A-Za-z\\u4E00-\\u9FA50\\u0021-\\u002F-9@#$&*()-+= /;:,.!?]+$.*";
      if (!nameRegular.test(forgetform.name)) {
        Notify.warn('请输入有效名称!');
        return false;
      }
      forgetform.name = forgetform.name.trim()
      if (forgetform.name.length < 2 || forgetform.name.length > 50) {
        Notify.warn('请输入2-50个字符!');
        return false;
      }
      if (forgetform.phone == null || forgetform.phone === '') {
        Notify.warn('手机号不能为空！');
        return false;
      }
      //手机正则表达式
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(forgetform.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }
      //不允许点击按钮
      btnContent.value = '登记中';
      isloading.value = true;
      //2、提交试用申请
      const etpUrl = "https://work.sview3d.com/liftcloud/usercenter/enterprises/action/etpAuthentication.jsp";
      const etpParams = new URLSearchParams();
      etpParams.append('innerId', innerId);
      etpParams.append('name', forgetform.name);
      etpParams.append('authenticationphone', forgetform.phone);
      etpParams.append('seegId', "41-1");
      etpParams.append('certificationSource', "0");
      proxy.$axios.post(etpUrl, etpParams).then(function (response) {
        console.log("-----------提交试用申请-------------")
        console.log(response)
        console.log("-----------提交试用申请 end-------------")
        const info = response.data.errorinfo;
        if (info.errorflag === '0') {
          //调用审核通过
          trialPassed();
        } else {
          Notify.danger('申请失败');
          //允许点击按钮
          isloading.value = false;
          btnContent.value = '登记';
        }
      }).catch(function (error) {
        proxy.$dialog({
          title: '错误提示',
          content: error,
          noCancelBtn: true
        });
        //允许点击按钮
        isloading.value = false;
        btnContent.value = '登记';
      });
    }
    //试用通过
    const trialPassed = () => {
      const url = "https://work.sview3d.com/checkStatusServlet";
      const params = new URLSearchParams();
      params.append('innerId', innerId);
      params.append('checkStatus', "0");
      params.append('status', "pass");
      proxy.$axios.post(url, params).then(function (response) {
        console.log("-----------提交试用申请-------------")
        console.log(response)
        console.log("-----------提交试用申请 end-------------")
        if (response.data.result === '0') {
          Notify.success('登记成功！');
          if (source.value === 'toProdetail') {
            // window.location.href = 'https://work.sview3d.com/phoneWeb/prodetail?productId=81c9b5f1ab39417790740ac0af9c8093&userId=' + Base64.encode(innerId) + '&fromtype=' + fromtype.value  + '&onlineid=' + onlineid.value
            // window.location.href = 'http://10.0.44.136:8081/phoneWeb/prodetail?productId=81c9b5f1ab39417790740ac0af9c8093&userId=' + Base64.encode(innerId) + '&fromtype=' + fromtype.value  + '&onlineid=' + onlineid.value
            router.replace({ 
              path: '/phoneWeb/prodetail', 
              query: { 
                productId: '81c9b5f1ab39417790740ac0af9c8093', 
                userId: Base64.encode(innerId),
                fromtype: fromtype.value,
                onlineid: onlineid.value
              } 
            });
          } else if (source.value === 'toOrder') {
            // window.location.href = 'https://work.sview3d.com/phoneWeb/order?innerId=' + Base64.encode(innerId) + '&fromtype=' + fromtype.value  + '&onlineid=' + onlineid.value
            // window.location.href = 'http://10.0.44.136:8081/phoneWeb/order?innerId=' + Base64.encode(innerId) + '&fromtype=' + fromtype.value  + '&onlineid=' + onlineid.value
            router.replace({ 
              path: '/phoneWeb/prodetail', 
              query: { 
                innerId: Base64.encode(innerId),
                fromtype: fromtype.value,
                onlineid: onlineid.value
              } 
            });
          } else {
            router.push({
              path: '/phoneWeb/member',
              query: {
                innerId: Base64.encode(innerId),
                etpName: Base64.encode(forgetform.name)
              }
            });
          }
        } else {
          Notify.danger('审核失败');
          //允许点击按钮
          isloading.value = false;
          btnContent.value = '登记';
        }
      }).catch(function (error) {
        proxy.$dialog({
          title: '错误提示',
          content: error,
          noCancelBtn: true
        });
        //允许点击按钮
        isloading.value = false;
        btnContent.value = '登记';
      });
    }


    onMounted(
      () => {
        if (innerId == null || innerId === '') {
          Notify.danger('访问异常,请重新登录！');
          router.push("/phoneWeb/")
          return false;
        }
        forgetform.phone = route.query.phone;
      }
    )
    return {
      forgetform,
      // change,
      clear,
      registerClick,
      trialPassed,
      returnClick,
      isloading,
      btnContent,
      source
    }
  },
}
</script>
<style scoped>
</style>
